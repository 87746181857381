import { render, staticRenderFns } from "./meus-pedidos.vue?vue&type=template&id=36f9518b&scoped=true"
import script from "./meus-pedidos.vue?vue&type=script&lang=js"
export * from "./meus-pedidos.vue?vue&type=script&lang=js"
import style0 from "./meus-pedidos.vue?vue&type=style&index=0&id=36f9518b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36f9518b",
  null
  
)

export default component.exports